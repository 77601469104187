/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
// @import url('https://fonts.googleapis.com/css?family=Indie+Flower&display=swap');

@font-face {
  font-family: "Opensans-reg";
  src: url("assets/fonts/Opensans/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "Opensans-bold";
  src: url("assets/fonts/Opensans/OpenSans-Bold.ttf");
}
@font-face {
  font-family: "Opensans-Extra";
  src: url("assets/fonts/Opensans/OpenSans-ExtraBold.ttf");
}

@font-face {
  font-family: "Opensans-light";
  src: url("assets/fonts/Opensans/OpenSans-light.ttf");
}
:root {
  --ion-font-family: "OpenSans-reg";
  // --ion-font-family: 'Indie Flower', cursive;
}
// .app_toast{
//   animation-name: animateToast;
//   animation-duration: 1s;
//   animation-iteration-count: 1;
//   animation-delay: 0.5s;
//   animation-direction: alternate;


// }
// @keyframes animateToast {
//   0%{
//     border: 5px solid black;
//   }
//   50%{
//     // width:70%;
//     border: 3px solid black;



//   }
//   100%{
//     // width:100%;
//     border: 1px solid black;



//   }
  
// }
.custom_loading {
  .loading-wrapper {
    background: transparent;
    box-shadow: none;
  }
  ion-spinner {
    display: none !important;
  }
}
.header-md::after {
  height: 0px !important;
  content: "";
}
.header-ios::after {
  height: 0px !important;
  content: "";
}
.footer-md::before {
  top: -2px;
  bottom: auto;
  background-position: left 0 top 0;
  height: 0px;
}
.footer-ios::before {
  top: -2px;
  bottom: auto;
  background-position: left 0 top 0;
  height: 0px;
}

.custom_loading {
  .loading-wrapper {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    padding: 10px;
  }
}
.home_search {
  padding: 0px !important;
  --border-radius: 10px;
  box-shadow: none;
  .searchbar-input {
    font-size: 12px;
    box-shadow: none;
    caret-color: #0071e6;
    padding-left: 45px;
  }
}
.cartPOP {
  //       --backdrop-opacity
  // --background
  // --box-shadow
  --height: auto;
  --max-height: 100vh;
  --max-width: 100vw;
  --min-height: 100vh;
  --min-width: 100vw;
  --offset-y: 2px;
  --offset-x: -5px;
  --width: 100vw;
  background: #00000034;
}
//     .sc-ion-alert-md-h{
//       button{

//     background: white;
//     // color: primary;
//     border-radius: 15px;
//     margin-left: 4px;
//       }
//     }
//     .alert-button-group .sc-ion-alert-md{
//       button{
//         background: white;
//         color: var(--ion-color-primary);
//         border-radius: 15px;
//         margin-left: 4px;
//       }

//       // background: linear-gradient(0deg, #0071e6, transparent);
//     }
// .alert-button-group.sc-ion-alert-md {

//     --background: linear-gradient(0deg, #0071e6, transparent);

// }
// .alert-wrapper {
//   .alert-button:first-child {
//     color: var(--ion-color-danger);
//   }
// }
.customAlert {
  .alert-button {
    background: var(--ion-color-primary);
    color: white;
  }
}
.alert-button.sc-ion-alert-ios:last-child {
  border-right: 0;
  font-weight: bold;
  background: #0071e6;
  color: white;
}
.searchbar-clear-icon.sc-ion-searchbar-md {
  margin-right: 10px;
}
.doordashModal {
  --background: #00000082;
  --box-shadow: none;

  app-doordash-error {
    width: 300px;
    height: 300px !important;
    margin: auto;
    border-radius: 10px;
  }
}
.doordashModal::part(content) {
  width: 100%;
  height: 100%;
  display: flex;
}
.tipModal {
  --background: #00000082;
  --box-shadow: none;

  app-tip-modal {
    width: 340px;
    height: 380px !important;
    margin: auto;
    border-radius: 10px;
  }
}
.tipModal::part(content) {
  width: 100%;
  height: 100%;
  display: flex;

}
.surveyAlert {
  --background: #00000082;
  --box-shadow: none;

  app-survey-alert {
    width: 340px;
    height: 300px !important;
    margin: auto;
    border-radius: 10px;
  }
}
.surveyAlert::part(content) {
  width: 100%;
  height: 100%;
  display: flex;

}

.phoneModal {
  --background: transparent;
  --box-shadow: none;

  app-ask-phone {
    width: 300px;
    height: 300px !important;
    margin: auto;
    border-radius: 10px;
  }
}
.phoneModal::part(content) {
  width: 100%;
  height: 100%;
  display: flex;

}
.leaderboard_filter{
  --background: #1a42a555;
  --box-shadow: none;
  app-leaderboard-filters {
    width: 300px;
    height: 500px !important;
    margin: auto;
    border-radius: 10px;
  }
}
.leaderboard_filter::part(content) {
  width: 100%;
  height: 100%;
  display: flex;

}
// .add_review_modal{

@media only screen and (min-height: 1200px) {
  .tipModal{
    app-tip-modal {
      width: 360px;
      height: 440px !important;
      
    }
  }

}
  // }
@media only screen and (min-width: 1200px) {
  .cartPOP {
    --height: 100vh;
    --max-height: 100vh;
    --max-width: 40vw;
    --min-height: 100vh;
    --min-width: 40vw;
    --offset-y: 0vh;
    --offset-x: 30vw;
    --width: 40vw;
  }
  .review_leaderBoard{
  
      --background: transparent;
      --box-shadow: none;
  
      // app-menu{
      //   width: 800px;
      // height: 800px;
      // margin: auto;
      // border-radius: 10px;
  
      // }
    
  
  }
  .review_leaderBoard::part(content) {
    width: 40vw !important;
    height: 80vh !important;
  }
  .leaderboard_filter::part(content) {
    width: 40vw !important; 
    height: 80vh !important;
  }

  .menuModal {
    --background: transparent;
    --box-shadow: none;

    // app-menu{
    //   width: 800px;
    // height: 800px;
    // margin: auto;
    // border-radius: 10px;

    // }
  }
  .menuModal::part(content) {
    width: 80vw;
    height: 80vh;
  }
}

//end of media q
.menu_icon {
  font-size: 32px;
}
.phoneModal {
  --background: transparent;
  --box-shadow: none;

  app-ask-phone {
    width: 300px;
    height: 300px;
    margin: auto;
    border-radius: 10px;
  }
}


.header-ios ion-toolbar:last-of-type {
  --border-width: 0 0 0;
}
